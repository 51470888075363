"use client"

import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { useTranslations } from "next-intl"
import { useMemo, useState } from "react"
import { FilterIcon, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { Apartment, jobFilter } from "utils"
import { ProjectsMessages } from "../Projects"
import AccordionItem from "./Accordion"
import FiltersModal from "./FiltersModal"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid black;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    border: none;
    padding-top: 0;
  }
`

const StyledTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    padding-top: 32px;
  }
`

const FilterButton = styled(Button)`
  padding: 0;
  min-width: 0;

  svg:hover > rect:first-of-type {
    fill: black;
  }
  svg:hover > rect:nth-of-type(2) {
    stroke: white;
  }
  svg:hover > path {
    fill: white;
  }
`
const ItemsFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 32px;
    padding-top: 32px;
  }
`

const StyledMoreButton = styled(Button)`
  text-decoration: none;
  font-size: 18px;
  color: black;
  align-self: center;
  border-bottom: 1px solid #000000;
  width: fit-content;
  border-radius: 0;
  min-width: 0;
  min-height: 0;
  height: fit-content;
  font-weight: 400;
  padding: 0;

  :hover {
    text-decoration: none;
  }
`

const NoResultButton = styled(Button)`
  text-decoration: none;
  border-bottom: 1px solid black;
  width: fit-content;
  font-size: 14px;
  line-height: 10px;
  color: black;
  border-radius: 0;
  align-self: flex-start;
  height: max-content;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 14px;
    line-height: 14px;
  }
  :hover {
    text-decoration: none;
  }
`

const StyledFilterCount = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 400;
  color: white;
  background-color: black;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 10px;
`

export type ApState = {
  floorMax: null | number
  floorMin: null | number
  areaMax: null | number
  areaMin: null | number
  date: Date | undefined
  roomNumber: null | string
  apartmentChar: Record<string, boolean>
  buildingChar: Record<string, boolean>
}

interface Character {
  [key: string]: boolean
}

export const createDefaultState = ({
  apartmProp,
  buildProp,
}: {
  apartmProp: string[]
  buildProp: string[]
}): ApState => {
  const apartmentChar = apartmProp.reduce((acc, prop) => {
    acc[prop] = false
    return acc
  }, {} as Character)
  const buildingChar = buildProp.reduce((acc, prop) => {
    acc[prop] = false
    return acc
  }, {} as Character)
  return {
    floorMax: null,
    floorMin: null,
    areaMax: null,
    areaMin: null,
    date: undefined,
    roomNumber: null,
    apartmentChar,
    buildingChar,
  }
}

export function filterApartments({
  apartments,
  state,
}: {
  apartments: Apartment[]
  state: ApState
}) {
  return apartments.filter((ap) => {
    let floorBool = true
    let areaBool = true
    let dateBool = true
    let roomNumberBool = true
    let apartmentCharBool = true
    let buildingCharBool = true
    if (state.floorMin !== null || state.floorMax !== null) {
      const floor = Number(ap.floor)
      const floorMin = state.floorMin ?? -Infinity
      const floorMax = state.floorMax ?? Infinity
      floorBool = ap.floor !== null ? floor >= floorMin && floor <= floorMax : false
    }

    if (state.areaMin !== null || state.areaMax !== null) {
      const area = Number(ap.areaInSquareMeters)
      const areaMin = state.areaMin ?? -Infinity
      const areaMax = state.areaMax ?? Infinity
      areaBool = ap.areaInSquareMeters !== null ? area >= areaMin && area <= areaMax : false
    }

    if (state.date) {
      const formatDate = new Date(state.date).setHours(0, 0, 0)

      dateBool = ap.entryDate ? new Date(ap.entryDate) >= new Date(formatDate) : false
    }
    if (state.roomNumber) {
      roomNumberBool = ap.numberOfRooms === state.roomNumber
    }
    const selectedApChars = Object.entries(state.apartmentChar)
      .filter(([, value]) => value)
      .map((el) => el[0])
    const selectedBuChars = Object.entries(state.buildingChar)
      .filter(([, value]) => value)
      .map((el) => el[0])
    if (selectedApChars.length > 0) {
      apartmentCharBool = selectedApChars.every((el) => ap.apartmentCharacteristics.includes(el))
    }
    if (selectedBuChars.length > 0) {
      buildingCharBool = selectedBuChars.every((el) => ap.buildingCharacteristics.includes(el))
    }
    return (
      floorBool && areaBool && dateBool && roomNumberBool && apartmentCharBool && buildingCharBool
    )
  })
}

type Props = {
  apartments: Apartment[]
  locale: string
  formSubjects: Record<string, string>
  messages: ProjectsMessages
  showHeader?: boolean
  bmbyId?: number
  status?: any
  allApChar: string[]
  allBuChar: string[]
  imageGalleries: {
    apartmentThumbnail?: string
    apartmentGallery?: string[]
    numberOfRooms?: number
  }[]
}

function PropertyTab({
  apartments,
  locale,
  formSubjects,
  messages,
  showHeader = true,
  bmbyId,
  status,
  allApChar,
  allBuChar,
  imageGalleries,
}: Props) {
  const t = useTranslations("BuildingInfo")
  const defaultState = useMemo(
    () => createDefaultState({ apartmProp: allApChar, buildProp: allBuChar }),
    [allApChar, allBuChar],
  )
  const [state, setState] = useState(defaultState)
  const [open, setOpen] = useState(false)
  const [visibleCount, setVisibleCount] = useState(6)
  const [filtersCount, setFiltersCount] = useState(0)
  const handleOpen = () => {
    jobFilter("Filter icon", "Filter icon", "פילטר מתקדם פרויקטים בשיווק ")
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 6)
  }

  const handleReset = () => {
    setState(defaultState)
    setFiltersCount(0)
  }
  const filteredApartments = filterApartments({ apartments, state })

  const visibleApartments = filteredApartments.slice(0, visibleCount)
  const count = filteredApartments.length

  return (
    <StyledContainer>
      <StyledTop>
        {showHeader && (
          <>
            {count > 0 ? (
              <NewText
                component={"p"}
                desktopFontSize={18}
                desktopLineHeight={"22px"}
                mobileFontSize={16}
                mobileLineHeight={"20px"}
                color={sharedColors.black}
              >
                {t("totalRent")} <strong>{count}</strong> {t("availableApartmentsRent")}{" "}
              </NewText>
            ) : (
              <NewText
                component={"p"}
                desktopFontSize={18}
                desktopLineHeight={"22px"}
                mobileFontSize={16}
                mobileLineHeight={"20px"}
                color={sharedColors.black}
              >
                סה״כ <strong>0</strong> דירות פנויות{" "}
              </NewText>
            )}
            <FilterButton onClick={handleOpen}>
              {filtersCount > 0 && <StyledFilterCount>{filtersCount}</StyledFilterCount>}
              <FilterIcon />
            </FilterButton>
          </>
        )}
        {open ? (
          <FiltersModal
            handleClose={handleClose}
            open={open}
            locale={locale}
            state={state}
            setState={setState}
            setFiltersCount={setFiltersCount}
            filtersCount={filtersCount}
            defaultState={defaultState}
            handleReset={handleReset}
            projectPage={true}
            allApChar={allApChar}
            allBuChar={allBuChar}
          />
        ) : null}
      </StyledTop>

      {visibleApartments.length < 1 && (
        <NoResultButton onClick={handleReset}>איפוס מסננים</NoResultButton>
      )}

      <ItemsFlex>
        {visibleApartments.map((el, i) => (
          <AccordionItem
            imageGallery={imageGalleries.find((x) => x.numberOfRooms === Number(el.numberOfRooms))}
            locale={locale}
            key={i}
            apartment={el}
            formSubjects={formSubjects}
            messages={messages}
            bmbyId={bmbyId}
            status={status}
          />
        ))}
        {visibleCount < filteredApartments.length && (
          <StyledMoreButton variant="text" onClick={handleShowMore}>
            {locale === "he" ? "דירות נוספות" : "More Apartments"}
          </StyledMoreButton>
        )}
      </ItemsFlex>
    </StyledContainer>
  )
}

export default PropertyTab
