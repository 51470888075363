"use client"

import styled from "@emotion/styled"
import { Tab, Tabs } from "@mui/material"
import { useTranslations } from "next-intl"
import { useState } from "react"
import { Container, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { ProjectPage, filterClick } from "utils"
import { ApartmentUmbraco } from "utils/projects"
import { ProjectsMessages } from "../../Projects"
import PropertyTab from "./PropertyTab"

const StyledContainer = styled.div<{ $paddingTop: boolean }>`
  padding-top: ${(p) => (p.$paddingTop ? "60px" : "32px")};
  gap: 32px;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(p) => (p.$paddingTop ? "60px" : "0px")};
    gap: 24px;
  }
`
const TopText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  gap: 8px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    ul {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
    text-align: left;
    margin: 0;
  }
`

const TabsContainer = styled(Tabs)`
  .MuiTabs-flexContainer {
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    ${(props) => props.theme.breakpoints.up("md")} {
      gap: 16px;
      justify-content: flex-start;
    }
  }
`

const StyledTab = styled(Tab)<{ active: boolean }>`
  font-size: 16px;
  line-height: 20px;
  padding: 14px 16px;
  border-radius: 8px;
  color: black;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  border: ${(props) => (props.active ? "2px" : "1px")} solid black;
  text-transform: none;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
    color: black;
    line-height: 25.92px;
    padding: 7px 16px;
  }
`
const ApartmentsContainer = styled.div<{ value: number }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.value !== 0 ? "32px" : "0px")};
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: ${(props) => (props.value !== 0 ? "40px" : "0px")};
  }
`

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
type TabProps = {
  value: number
  index: number
  apartments: ApartmentUmbraco[]
  locale: string
  formSubjects: Record<string, string>
  messages: ProjectsMessages
  bmbyId?: number
  status?: any
  el?: any
}

function ActiveTab({
  index,
  value,
  apartments,
  locale,
  formSubjects,
  messages,
  bmbyId,
  status,
}: TabProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== 0 && value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={value === 0 && index === 0 ? { marginTop: "40px" } : {}}
    >
      {(value === 0 || value === index) && (
        <PropertyTab
          apartments={apartments}
          locale={locale}
          formSubjects={formSubjects}
          messages={messages}
          showHeader={value !== 0 || index === 0}
          bmbyId={bmbyId}
          status={status}
        />
      )}
    </div>
  )
}

type Props = {
  projectData: ProjectPage
  propertyTypes: { id: number; value: string }[]
  locale: string
  formSubjects: Record<string, string>
  messages: ProjectsMessages
}

function ApartmentsUmbraco({ projectData, locale, formSubjects, messages }: Props) {
  const [value, setValue] = useState(0)
  const { apartments, apartmentsTypes } = projectData
  const t = useTranslations("BuildingInfo")
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    if (apartmentsTypes[newValue - 1]) {
      filterClick(apartmentsTypes[newValue - 1].value, "פילטר סוג פרוייקט בעמוד פרוייקטים")
    }
  }

  return (
    <Container>
      <StyledContainer
        $paddingTop={
          projectData.titleAboveProperty.length > 0 ||
          projectData.textAboveProperty.length > 0 ||
          apartments.length > 0
        }
      >
        {(projectData.titleAboveProperty.length > 0 ||
          projectData.textAboveProperty.length > 0) && (
          <TopText>
            <NewText
              component={"h2"}
              desktopFontSize={32}
              desktopLineHeight={"36px"}
              mobileFontSize={24}
              mobileLineHeight={"28px"}
              color={sharedColors.black}
            >
              {projectData.titleAboveProperty}
            </NewText>
            <NewText
              component={"div"}
              desktopFontSize={24}
              desktopLineHeight={"35.92px"}
              mobileFontSize={18}
              mobileLineHeight={"24px"}
              color={sharedColors.black}
              dangerouslySetInnerHTML={{
                __html: projectData.textAboveProperty,
              }}
            />
          </TopText>
        )}
        {apartments.length > 0 && (
          <ApartmentsContainer value={value}>
            {apartmentsTypes?.length > 0 && (
              <TabsContainer value={value} onChange={handleChange} aria-label="property types">
                <StyledTab
                  active={value === 0}
                  key={"all"}
                  label={t("showAll")}
                  style={{ fontSize: 20 }}
                />
                {apartmentsTypes.map((el: any, index: number) => (
                  <StyledTab
                    active={index + 1 === value}
                    key={index + 1}
                    label={el.name}
                    {...a11yProps(index)}
                    style={{ fontSize: 20 }}
                  />
                ))}
              </TabsContainer>
            )}
            {value === 0 ? (
              <ActiveTab
                locale={locale}
                apartments={apartments}
                index={0}
                value={value}
                formSubjects={formSubjects}
                messages={messages}
                bmbyId={projectData?.bmbyId}
                status={projectData?.projectStatuses[0]?.name}
              />
            ) : (
              apartmentsTypes.map((el: any, index: number) => {
                return (
                  <ActiveTab
                    key={index}
                    locale={locale}
                    apartments={apartments.filter((ap) => ap.propertyType?.id === el.id)}
                    index={index + 1}
                    value={value}
                    el={el}
                    formSubjects={formSubjects}
                    messages={messages}
                  />
                )
              })
            )}
          </ApartmentsContainer>
        )}
      </StyledContainer>
    </Container>
  )
}

export default ApartmentsUmbraco
