import styled from "@emotion/styled"
import moment from "moment"
import Link from "next/link"
import { useState } from "react"
import { NewText } from "ui"
import TabPanel from "ui/TabPanel"
import { sharedColors as colors } from "ui/theme/colors"
import BondsTabTilte from "./BondsTabTitle"
import BondsTable from "./BondsTable"
import { InvestoreRelationsMessages } from "./InvestorsRelation"

type Props = {
  bondsData: any
  type?: string
  messages: InvestoreRelationsMessages
  locale: string
}

const StyledDiv = styled.div<{ $type?: string }>`
  margin-top: 24px;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${(p) => (p.$type === "inner" ? "100%" : "fit-content")};
    margin-top: 40px;
  }
`

const StyledLink = styled(Link)`
  width: 153px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${colors.black};
  border-radius: 200px;
  text-decoration: none;
`

const StyledBottomDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
  }
`

function Bonds({ bondsData, type, messages, locale }: Props) {
  const [value, setValue] = useState(0)

  return (
    <StyledDiv $type={type}>
      <BondsTabTilte data={bondsData?.bonds} value={value} setValue={setValue} />
      {bondsData?.bonds.map((data: any, index: number) => (
        <TabPanel value={value} index={index} key={index.toString()}>
          <BondsTable data={data} type={type} messages={messages} />
          {type !== "inner" && (
            <StyledBottomDiv>
              <NewText
                component={"p"}
                desktopFontSize={18}
                desktopLineHeight={"24px"}
                desktopFontWeight={400}
                mobileFontSize={14}
                mobileLineHeight={"18px"}
                color={colors.black}
              >
                * {messages.lastUpdate}: {moment(data?.lastTransaction).format("DD/MM/YYYY HH:mm")}
              </NewText>

              <StyledLink href={`${locale === "en" ? "/en" : ""}/investors-relations/bonds`}>
                <NewText
                  component={"p"}
                  desktopFontSize={20}
                  desktopLineHeight={"20px"}
                  desktopFontWeight={600}
                  color={colors.black}
                >
                  {messages.tobonds}{" "}
                </NewText>
              </StyledLink>
            </StyledBottomDiv>
          )}
        </TabPanel>
      ))}
    </StyledDiv>
  )
}

export default Bonds
